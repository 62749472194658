import React, { Fragment, useEffect, useState, useRef } from "react";
import { fade } from "./CommonSlide.module.css";

const CommonSlide = ({
  images,
  imageClass,
  outerClass,
  imageStyle,
  intervals = 5000,
  title,
  showDots,
  fixed,
}) => {
  let len = images?.length;
  const [show, setShow] = useState(0);
  const timeOutRef = useRef(null);

  function resetTimeout() {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }
  }

  useEffect(() => {
    if (!fixed) {
      resetTimeout();

      timeOutRef.current = setTimeout(
        () => setShow((show + 1) % len),
        intervals
      );

      return () => resetTimeout();
    }
  }, [show]);

  return (
    <div
      ref={timeOutRef}
      className={`${outerClass} ${fade}`}
      style={{
        ...(!fixed
          ? {
              backgroundColor:
                show === 0 ? "#d3e0d7" : show == 1 ? "#f1f9fc" : "#ede4de",
            }
          : null),
      }}
    >
      {images?.map((bgImg, index) => (
        <img
          src={bgImg}
          key={index}
          className={`${imageClass} ${
            show == index ? "block" : "hidden"
          } ${fade}`}
          style={imageStyle}
        ></img>
      ))}
      {/* here used to be the company logo */}
      {showDots && (
        <p
          className="absolute top-0"
          style={{
            marginLeft: "87px",
            marginTop: "137px",
            height: "64px",
            width: "240px",
            color: "#29333B",
            font: "normal normal bold 24px/31px Open Sans",
            textAlign: "left",
          }}
        >
          {title[show]}
        </p>
      )}

      {showDots && (
        <div
          className="absolute top-0 w-full flex space-x-1"
          style={{ marginLeft: "87px", marginTop: "250px" }}
        >
          <span
            style={{
              width: show === 0 ? "18px" : "6px",
              height: "6px",
              backgroundColor: "#85958A",
              borderRadius: "20px",
            }}
            className={`inline-block cursor-pointer`}
            onClick={() => setShow(0)}
          ></span>
          <span
            style={{
              width: show === 1 ? "18px" : "6px",
              height: "6px",
              backgroundColor: "#85958A",
              borderRadius: "20px",
            }}
            className={`inline-block cursor-pointer`}
            onClick={() => setShow(1)}
          ></span>
          <span
            style={{
              width: show === 2 ? "18px" : "6px",
              height: "6px",
              backgroundColor: "#85958A",
              borderRadius: "20px",
            }}
            className={`inline-block cursor-pointer`}
            onClick={() => setShow(2)}
          ></span>
        </div>
      )}
    </div>
  );
};

export default CommonSlide;
