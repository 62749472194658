import React from "react";
import BpOnBoarding from "../containers/BP/BPOnBoarding/index";
import ConsuemrOnBoarding from "../containers/Consumer/ConsumerOnBoarding/index";
// import { enableBP } from "gatsby-env-variables";
import UpdateButton from "../common/components/updateButton/UpdateButton";
const Consumer = () => {
  return (
    <UpdateButton message="This application has been updated. It will be reloaded soon.">
      <ConsuemrOnBoarding />
    </UpdateButton>
  );
};

export default Consumer;
