import React, { useState, useEffect, useContext } from "react";
import { useFormikContext } from "formik";
import { useFormikWizard } from "formik-wizard";
import * as _ from "lodash";
import PhoneInput from "react-phone-input-2";
import CommonInput from "../../../../common/components/CommonComponent/CommonInput";
import parsePhoneNumber from "libphonenumber-js";
import "react-phone-input-2/lib/material.css";
import BottomModal from "../../../../common/components/bottomLoginModal/BottomModal";
import withErrorHandlerComponent from "../../../../hoc/withErrorHandler/withErrorHandlerComponent";
import ErrorAltIcon from "../../../../images/icons/errorAltIcon.svg";
import useWindowResize from "../../../../hooks/useWindowResize";
import { DeveloperDataContext } from "../../../../common/utils/appContext";
import { Append_0 } from "../../../../common/constants/Append_0";
import { setFiltersInfo } from "../../../../common/utils/FiltersInfo";

const ConsumerDetails = props => {
  const [mobileNumber, setMobileNumber] = useState("");
  const Context = useContext(DeveloperDataContext);
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldError,
    validateForm,
    setFieldTouched,
    resetForm,
    handleSubmit,
    isSubmitting,
    isValid,
  } = useFormikContext();
  const { values: wizardValues, status } = useFormikWizard();
  const [windowWidth] = useWindowResize();
  useEffect(() => {
    validateForm();
  }, []);

  return (
    <BottomModal
      isLogin
      className={`w-full flex flex-col font-roboto justify-between pt-10 md:pt-14 pb-19 md:pb-4 lg:max-w-md mx-auto lg:px-11 px-2 overflow-auto  ${
        windowWidth > 767 ? "" : " bg-white "
      }`}
      style={{
        height: "calc(100% - 135px)",
      }}
    >
      <div className="max-w-full md:px-2 px-4 mt-2">
        <div className="w-full mx-auto mb-8">
          <CommonInput
            name="name"
            id="name"
            classNameProp="mx-auto"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.name}
            legendName="Enter your Name"
            error={touched?.name && errors?.name}
            touched={touched?.name}
          />
        </div>
        <div className="w-full mx-auto">
          <PhoneInput
            country={"in"}
            value={"+" + values?.dialCode + values?.mobile}
            specialLabel={"Phone number"}
            countryCodeEditable={false}
            inputProps={{
              name: "mobile",
              required: true,
              autoFocus: false,
              style: {
                width: "100%",
                height: "50px",
                border: "1px solid #DCE9F5",
                "&:hover": {
                  border: "1px solid #00ACB8",
                },
                "&:focus": {
                  border: "1px solid #00ACB8",
                  boxShadow: "0 0 0 1px #00acb8",
                },
              },
            }}
            enableSearch
            searchStyle={{
              width: "100%",
              maxWidth: "220px",
              height: "40px",
              "&:hover": {
                border: "1px solid #00ACB8",
              },
              "&:focus": {
                border: "1px solid #00ACB8",
              },
            }}
            dropdownStyle={{ width: "250px", maxWidth: "250px" }}
            onlyCountries={[
              "in",
              "ae",
              "us",
              "ca",
              "gb",
              "sa",
              "qa",
              "sg",
              "hk",
              "my",
              "de",
              "it",
              "jp",
              "kw",
              "mv",
              "np",
              "lk",
              "th",
              "cn",
              "br",
              "bt",
              "au",
            ]}
            onChange={(rawValue, countryData, event, formattedValue) => {
              let phoneNumber = parsePhoneNumber(
                formattedValue,
                countryData.countryCode
              );
              if (phoneNumber) {
                setFieldValue("mobile", phoneNumber.nationalNumber);
                setFieldValue("dialCode", phoneNumber.countryCallingCode);
                setFieldValue("countryCode", countryData.countryCode);
                setMobileNumber(formattedValue);
              }
            }}
            onBlur={(e, countryData) => {
              let phoneNumber = parsePhoneNumber(
                mobileNumber,
                countryData.countryCode
              );
              if (phoneNumber) {
                if (!phoneNumber.isValid()) {
                  setFieldError("mobile", "Please enter correct phone number");
                }
              }
            }}
            error={touched?.mobile && errors?.mobile}
            errorMsg={errors?.mobile}
            touched={touched?.mobile}
          />
          <p className="text-sm  mt-1 text-grey-50">
            We will use your number to send OTP
          </p>
        </div>
        {status?.message && (
          <div
            className="flex space-x-2 w-full p-3 px-4 items-center mt-4"
            style={{
              backgroundColor: "#FAEDE9",
            }}
          >
            <img src={ErrorAltIcon} alt="oops!" />
            <div className="text-black-400 text-sm">
              {withErrorHandlerComponent(status?.message)}
            </div>
          </div>
        )}
      </div>
      <div className="max-w-full md:mb-6 lg:mb-0 px-5 md:px-2 mt-22">
        <button
          id="submit"
          className="newButton newSignUp w-full h-13"
          type="submit"
          disabled={isSubmitting || !_.isEmpty(errors) || values?.mobile < 10}
          onClick={() => {
            if (Context.appData.loginOtpError) {
              delete Context.appData.loginOtpError;
            }
          }}
        >
          Request OTP
        </button>

        <div className="text-center py-4 text-black-400">
          Back to Login?
          <span
            className="text-blue-500 cursor-pointer ml-1"
            onClick={() => (location.href = "/?&user=consumer")}
          >
            Click Here
          </span>
        </div>
      </div>
    </BottomModal>
  );
};

export default ConsumerDetails;
