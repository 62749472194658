import React, { useState, useEffect } from "react";
import Steps from "./Steps";
import FormikWizard from "formik-wizard";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Header from "../../../common/components/Header/Header";
import useWindowResize from "../../../hooks/useWindowResize";
import newLogoSmartAsset from "../../../images/newLogoSmartAsset.svg";
import { put, get as apiGet } from "../../../common/utils/api-utils";
import {
  createPasswordv1 as createPasswordApi,
  login,
} from "../../../common/constants/api";
import { getUserId, isLoggedIn } from "../../../common/utils/cookie-utils";
import config from "../../../../data/SiteConfig";
import CircularLoading from "../../../common/components/CircularLoading/CircularLoading";
import _ from "lodash";
import loginPagePic from "../../../images/icons/newLogin/loginPage.png";
import mainPagePic from "../../../images/icons/newLogin/mainPage.png";
import anotherImage from "../../../images/icons/newLogin/anotherImage.png";
import loginPageImgFirst from "../../../images/consumer/ConsumerSignUp1.png";
import loginPageImgSecond from "../../../images/consumer/ConsumerSignUp2.png";
import loginPageImgThird from "../../../images/consumer/ConsumerSignUp3.png";
import CommonSlide from "../../../common/components/CommonImageSlide/CommonSlide";
import { navigate } from "gatsby";
import UpdateButton from "../../../common/components/updateButton/UpdateButton";
import mainPageIcon from "../../../images/icons/newLogin/mainPageLogo.svg";
import { GA_EVENTS, gtagTrackEvent } from "../../../common/constants/GA4";
import { Dashboard } from "@material-ui/icons";
import {
  setIsApproved,
  setRole,
  setTeam,
  setToken,
} from "../../../common/utils/auth-utils";
import { setAcl } from "../../../common/utils/acl-utils";
import { setUserDetailsInfo } from "../../../common/utils/userDetailsInfo";

function FormWrapper({ children, canGoBack, wizard, steps, values }) {
  const [windowWidth, windowHeight] = useWindowResize();
  const [updatingChange, setUpdatingChange] = useState(false);
  const url = new URL(window.location.href);
  const getForm = () => url.searchParams.get("formOut") || false;
  const getPhone = () => url.searchParams.get("phone") || "";
  const [fromOut, setFromOut] = useState(getForm());
  const [phone, setPhone] = useState(getPhone());
  const images = [loginPageImgFirst, loginPageImgSecond, loginPageImgThird];
  const title = [
    "Home is where the heart is.",
    "Make your Dream Home Reality!",
    "Its not a property , its a business opportunity",
  ];
  useEffect(() => {
    if (isLoggedIn()) {
      setUpdatingChange(true);
      if (config.askForPasswordConsumer) {
        wizard.push(steps[3]);
        config.askForPasswordConsumer = true;
      }
      setUpdatingChange(false);
    }
    if (fromOut && config.moveNextBP) {
      wizard.push(steps[1]);
      values.login.mobile = phone;
      config.moveNextBP = false;
    }
  }, []);
  const handleGoBack = () => {
    if (!canGoBack) return;
    let currStepIndex = wizard.steps.indexOf(wizard.step);
    wizard.push(steps[currStepIndex - 1]);
  };
  const clickFunc = () => {
    let currStepIndex = wizard.steps.indexOf(wizard.step);
    wizard.push(steps[currStepIndex]);
  };
  return (
    <UpdateButton
      className="w-full flex flex-row min-h-full h-screen bg-white overflow-auto scrolling-auto"
      message="This application has been updated. It will be reloaded soon."
    >
      <div className=" w-full h-screen flex md:items-center items-start">
        <div
          className={`relative md:w-89 w-full md:m-auto m-0 md:py-0 py-13 ${
            windowWidth > 767 ? ` ` : ``
          } md:justify-center justify-start space-x-3 md:my-8 xxl:my-25 xxxl:40`}
        >
          <div
            className="flex flex-col place-self-start content-center w-full"
            style={{
              height: "calc(100vh - 104px)",
            }}
          >
            <div className="mt-9"></div>
            <div
              className={
                "md:px-13 px-6 text-base xxs:text-xl md:text-xl mb-5 md:mb-2 w-full"
              }
              style={{
                color: "#394759",
              }}
            >
              {wizard?.step?.id == "login" ||
              (canGoBack &&
                wizard?.step?.id !== "bPVerified" &&
                wizard?.step?.id !== "bPVerificationPending" &&
                wizard?.step?.id !== "bPRejected") ? (
                <img src={mainPageIcon} alt="fincity" />
              ) : (
                ""
              )}
            </div>
            <div className="flex w-full md:items-center md:px-13 px-6">
              <div
                className="mt-2 md:mt-8 font-normal text-xl md:text-3xl"
                style={{
                  color: "#394759",
                }}
              >
                {wizard?.step?.id === "login" ? (
                  <p className="headerColor">
                    Sign Up as <span>Consumer</span>
                  </p>
                ) : wizard?.step?.id === "otp" ? (
                  <p className="headerColor">
                    Verify Your <span> OTP</span>
                  </p>
                ) : wizard?.step?.id === "confirmPassword" ? (
                  <p className="headerColor">
                    Set Your <span> Password</span>
                  </p>
                ) : wizard?.step?.id === "consent" ? (
                  <p className="headerColor">
                    Terms & <span> Conditions</span>
                  </p>
                ) : wizard?.step?.id === "bPVerified" ? (
                  ""
                ) : (
                  <p className="headerColor">
                    Consumer <span> Onboarding</span>
                  </p>
                )}
              </div>
            </div>

            {updatingChange ? <CircularLoading /> : children}
          </div>
        </div>
      </div>
      <div className="w-full h-screen bgImage md:block hidden"></div>
    </UpdateButton>
  );
}
const Index = () => {
  const handleSubmit = React.useCallback(async values => {
    await createPassword(values?.confirmPassword, values);
    gtagTrackEvent(GA_EVENTS.BP_THANK_PAGE);
  }, []);
  let createPassword = async (sectionValues, formValues) => {
    if (sectionValues?.confirmPassword) {
      try {
        let body = {
          password: sectionValues?.confirmPassword,
          changePasswordDto: {
            oldPassword: "",
            newPassword: sectionValues?.confirmPassword,
          },
          deviceType: formValues?.otp?.device,
        };
        await createPasswordApi(body, config?.forgotPassTempToken?.token);
        try {
          let res = await login({
            phoneNumber: formValues?.login?.mobile,
            password: sectionValues?.password,
          });
          let [id, username, userType, token, team, role, acl, checks, name] = [
            res?.id,
            res?.username,
            res?.userType,
            res?.token,
            res?.team,
            res?.role,
            res?.acl,
            res?.checks,
            res?.name,
          ];
          await setToken({ id, token, username, userType });
          setAcl(acl);
          setRole(role);
          setTeam(team);
          let obj = {
            username: username,
            name: name,
          };
          setIsApproved(checks?.isApproved);
          setUserDetailsInfo(obj);
          userType == 1
            ? navigate("/app/dashboard/bp")
            : userType == 3
            ? navigate("/app/Consumer/Profile")
            : navigate("app/dashboard");
        } catch (err) {
          throw new Error(err.response?.data?.message || "some error occurred");
        }
      } catch (err) {
        throw new Error(err.response?.data?.message || "some error occurred");
      }
    }
  };
  useEffect(() => {
    isLoggedIn() ? navigate("/app/Consumer/Profile") : null;
  });

  return (
    <FormikWizard steps={Steps} onSubmit={handleSubmit} render={FormWrapper} />
  );
};
export default Index;
