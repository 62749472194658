import React from "react";
import { object, array, string, number, bool, ref } from "yup";
import ConsumerDetails from "./components/ConsumerDetails";
import Otp from "../../../common/components/GlobalOtpComp/GlobalOtpComp";
import {
  sendOtpToConsumer,
  verifyConsumerOtp,
  createPassword as createPasswordApi,
} from "../../../common/constants/api";
import { setToken } from "../../../common/utils/auth-utils";
import { setAcl } from "../../../common/utils/acl-utils";
import config from "../../../../data/SiteConfig";
import { GA_EVENTS, gtagTrackEvent } from "../../../common/constants/GA4";
import createPassword from "../../../components/CreatePassword/CreatePassword";

const Steps = [
  {
    id: "login",
    component: ConsumerDetails,
    initialValues: {
      name : "",
      dialCode : "+91",
      mobile: "",
    },
    validationSchema: object().shape({
      name:  string()
        .required("Required"),
      dialCode: string()
        .required("Required"),
      mobile: string()
        .required("Required")
        .max(10)
        .matches(/([6-9]\d{9}$)/, "Mobile should be 10 digit number"),
    }),
    onAction: async (sectionValues, formValues) => {
      let body = {
        name : sectionValues?.name,
        dialCode : sectionValues?.dialCode,
        phoneNumber : sectionValues?.mobile,
        email : ""
      }
      await sendOtpToConsumer(body)
        .then(res => {
          gtagTrackEvent(GA_EVENTS.REGISTERED_BP);
        })
        .catch(err => {
          throw new Error(
            err.response.data.message || "Oops! something went wrong !!"
          );
        });
    },
  },
  {
    id: "otp",
    component: Otp,
    initialValues: {
      mobileOtp: "",
      resMsg: "",
    },
    onAction: async (sectionValues, formValues) => {
      let body = {
        phoneNumber: formValues?.login?.mobile,
        password: null,
        otpLogin: {
          otpLogin: true,
          otp: sectionValues?.mobileOtp
        },
        deviceType: sectionValues?.device
      }
      await verifyConsumerOtp(body)
        .then(async res => {
          gtagTrackEvent(GA_EVENTS.VERIFY_OTP_BP);
          let [id, token, username, userType] = [
            res.id,
            res.token,
            res.username,
            res.userType,
          ];
          await setToken({ id, token, username, userType });
          setAcl(res?.acl);
          config.askForPasswordConsumer = res?.checks.askForPassword;
          config.forgotPassTempToken = res?.token;
        })
        .catch(err => {
          throw new Error(
            err.response.data.message || "Oops! something went wrong !!"
          );
        });
    },
  },
  {
    id: "confirmPassword",
    component: createPassword,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: object().shape({
      password: string()
        .min(8, "Password must be at least 8 characters")
        .max(15, "Password must not be longer than 15 characters")
        .required("Password is required")
        .matches(
          /[!@#$%^&*()\-_=+{};:,<.>]/g,
          "Must contain an Special Charecter"
        ),
      confirmPassword: string()
        .min(8, "Please confirm your password")
        .required("Must match the password above ")
        .oneOf([ref("password"), null], "Passwords must match"),
    }),
  },
];
export default Steps;
